<template>
  <div>
    <c-tab
      ref="ctomissTab"
      type="horizon"
      :inlineLabel="true"
      :dense="true"
      :height="tabHeight"
      :tabItems="tabItems"
      v-model="tab"
      @tabClick="tabClick"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :count="count"
          :popupParam.sync="popupParam"
          :contentHeight="contentHeight"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
export default {
  name: 'consolidationCheckDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        saiJointInspectionId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'councilInfo',
      editable: true,
      count: 0,
    };
  },
  computed: {
    disabled() {
      return false;
    },
    tabItems() {
      let _items = [
        // 기본정보 및 참석자
        { name: 'councilInfo', icon: 'edit', label: '기본정보 및 참석자', component: () => import(`${'./consolidationCheckInfo.vue'}`) },
      ]
      if (this.popupParam.saiJointInspectionId) {
        _items.push(
          // 개선관리 
          { name: 'councilImpr', icon: 'construction', label: '개선관리', component: () => import(`${'./consolidationCheckImpr.vue'}`), },
        )
      }
      return _items;
    },
    tabHeight() {
      return String(this.contentHeight - 36);
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
    tabClick() {
      this.count++;
    },
  }
};
</script>
